.startup-singam {
  
 display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
    background-color: black;
    
}

.herader-sec img {
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 65vh;
    background-color: yellow;
}

.start-cont {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: row;
    gap: 2px;
    padding-top: 5%;
    
    background-color: white;
    border-radius: 20px;
}

.start-cont img {
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 550px;
}


.video video 
{
    width: 600px;
}



.content .firstpara p{
  font-size: 14px;
  text-align: justify;
  padding: 5%;
}

.start-cont .content {

    width: 100%;
   padding-right: 5%;
}

.herader-sec img
{
    width:550px;
    object-fit: contain;
    background-color: black;
}

@media(max-width:768px) {
    .startup-singam
    {
        padding: 2%;
        padding-top: 10%;
    }
    .herader-sec img {
        height: 100%;
        width: 100%;
    }
    .start-cont {
        flex-direction: column;
        gap: 20px;
    }
    .start-cont img {
        height: 450px;
    }
   .typewriter h3
   {
    
   }
   
.video video 
{
    width: 350px;
}

    

}
.second-sec
{
    align-items: center;
    justify-content: center;
}
.dev p{
    font-weight: 700;
}
.first p ,.second p{
    font-size: 14px;
    color: rgb(80, 80, 80);
    font-weight: 500;
}
.bold
{
    color: rgb(107, 0, 126);
    font-size: 17px;
    font-weight: 600;
}
.content p{
    text-align: justify;
}
.typewriter h3 {
    overflow: hidden;
    border-right: .15em solid orange; 
    white-space: nowrap; 
    margin: 0 auto;

    font-size:30px;
       animation: 
      typing 2.5s steps(40, end),
      blink-caret .75s step-end infinite;
      color: tomato;
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }

  .content
  {
    width: 500px;
    padding-left: 5%;
  }