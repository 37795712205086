/* OngoingProjectsPage.css */

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap');

.ongoing-projects-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'roboto' , sans-serif;
  }
  .First-content
  {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2%;
         
  }
  .right-container h3
  {
    width: 100%;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    color: #5a2a83; 
  }
  .right-container p 
  {
    color: rgb(42, 42, 42);
  }
.left-container
{
  width: 100%;
}
  