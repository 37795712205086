
.slider {
  position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-top: 10%;
  }
  .slides {
    display: flex;
    height: 100%;
    transition: transform 0.5s ease;
  }
  .slide {
    min-width: 100%;
    height: 100%;
    opacity: 0; 
    transition: opacity 0.5s ease-in-out; 
    position: absolute;
    top: 0;
    left: 0;
}
  .slide.active {
    opacity: 1;
    position: relative; 
  }
  .welcome-img  {
    width: 100%;
    height: 100vh; 
    object-fit: cover;
    display: block; 
  }
  .prev, .next {
    
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 50%;
    z-index: 10;
  }
  .prev {
    left: 10px;
  }
  .next {
    right: 10px;
  }
  .navigation-manual {
    position: absolute;
    width: 100%;
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .manual-btn {
    display: none;
    border: 1px solid #ffffff;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.4s;
    margin: 0 5px;
  }
  .manual-btn.active {
    background-color: #ffffff;
  }
 @media(max-width:768px)
 {
  .slider{
    height: 25vh;
  }
  .slide .welcome-img{
    height: 25vh;
  }
 }