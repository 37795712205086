
body{
  background-color: #ffff;
  overflow-x: hidden;
}
.header{
    top: 0;
    left: 0;
    width: 100%;
    padding: 2px 10%;
    background: #fff;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items:center;
    z-index:100;
}
a{
    text-decoration: none;
}


.logo img {
  width: 100px;
  height: 100px; 
  object-fit: contain; 
}

/* For responsiveness */
@media (max-width: 768px) {
  .logo img{
    width: 80px; /* Smaller width on smaller screens */
    height: 80px; /* Smaller height on smaller screens */
  }
}
.navbar a{
    position: relative;
    font-size: 18px;
    color: #5a2a83;
    font-weight: 700;
    margin-left: 40px;
    z-index: 1999;
} 
.navbar a::before{
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 0%;
    height: 2px;
    background-color: #ffffff;
    transition: .3s;
} 
.navbar a:hover::before{
    width: 100%;
    border-radius: 100%;
    background-color: #5a2a83;
} 

.mobile-menu-icon {
    display: none;
    font-size: 30px;
    color: #5a2a83;
    cursor: pointer;
   
  }
  @media (max-width: 768px) {
    .navbar {
      display: none; 
      flex-direction: column;
      gap: 20px;
      position: absolute;
      top: 60px;
      width: 80%;
      left: 0;
      width: 100%;
      background-color: #ffffff;
     padding-top:30px;
     z-index: 1;
     padding-bottom: 25px;
     
    }
    .navbar a{
      font-size: 18px;
      font-weight: 550;
      padding-bottom: 5px;
    } 
    .navbar.active {
      display: flex; 
      flex-direction: column;
    } 
    .mobile-menu-icon {
      display: block; 
    }
    .header{
      margin: 0;
    }
  }

  .home-welcome-page {
    display: flex; 
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    
  }
  .home-welcome-page .welcome-img1 {
    max-width: 100%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease; 
  }
@media (max-width: 768px) {
  .home-welcome-page  {

   height: 25vh;
  }
}
@media (max-width: 480px) {
  .home-welcome-page .welcome-img1 {
    max-width: 100%; 
    height: 25vh;
  }
}