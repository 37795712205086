.back-ground
{
    width: 100%;
    height: 100%;
    color: #fffF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 5%;
    padding-bottom: 5%;
}

.para-title
{
    width: 900px;
    height: 100px;
    background-color: #5a2a83;
    border-radius: 35px 35px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center ;    
    color: rgb(255, 255, 255);
}
.para-title h1{
    font-weight: 600;
}
.paragraph
{
    padding-top: 2%;
    width: 900px;
    height: 150px;
    background-color:#5a2a83;
    border-radius: 0px 0px 50px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
}
@media(max-width:768px)
{
    .paragraph
    {
        width: 100%;
       
    }   
    .back-ground
{
    position: relative;
    top: 322px;
}
    .promo
    {
        position: relative;
        top: 290px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    padding-left: 0%;
    }
    .promo{
        border: 0.1rem solid #5a2a83;
    }
    .promo img
    {
    width: 300px;
    }
    .promo p .startup{
        color: #ff5900;
        font-size: 20px;
        font-weight: 600;
      }

}
.promo h3{
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 2%;
    color: #5a2a83;

}

.para-inside
{
    width: 750px;
}
.img-Suresh
{
width: 100%;
height: 100%;
background-color: rgb(255, 255, 255);
display: flex;
justify-content: center;
align-items: center;
border-left: 0.1rem solid #5a2a83;
border-right: 0.1rem solid #5a2a83;
}
.img-Suresh img{
    width: 300px;
    height: 380px;
    object-fit: cover;
}

@media(max-width:768px)
{
    .para-title
    {
        width:90%;
        border-radius: 25px 25px 0px 0px;
    }
    .img-Suresh img{
        width: 230px;
        height: 300px;
    }
    
    .para-title h1
    {
        padding-top: 5px;
        font-size: 18px;
        font-weight: 700;
    }

    
    .paragraph
    {
        width: 90%;
        height: 50%;
    }
    .para-inside
    {
        font-size: 14px;
        font-weight: 600;
        padding: 15px;
    }
}