/* glassmorphi.css */

/* Variables */
:root {
  --foreground-color: white;
  --size: 6rem;
  --transition-duration: 500ms;
  --transition-easing: ease-out;
}

/* Global Styles */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Background Alignment */
.align-bg {
  background-color: transparent;
}
@media(max-width:768px)
{
  .align-bg{
    height: 650px;
    padding-top: 30px;
  }
}

/* Profile Card Background */
.profile-card-background {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: -150px; /* You may want to adjust this for responsive design */
}

/* Profile Card */
.profile-card {
  width: 50rem;
  height: 35rem;
  background-color: #5a2a83;
  overflow: hidden;
  transition: transform var(--transition-duration) var(--transition-easing);
  backdrop-filter: blur(5px);
  border: 1px solid #260246;
  border-radius: 12px;
  position: relative;  
}

 .profile-card::before {
  content: '';
  background: rgba(160, 159, 159, 0.4);
  width: 30%;
  height: 100%;
  position: absolute;
  
  top: 0;
  left: -125%;
  transform: skew(45deg);
  transition: left var(--transition-duration) var(--transition-easing);
}

.profile-card:hover::before {
  left: 150%;
} 
.img-holder {
  width: 20rem;
  height: 20rem;
  background-color: rgb(221, 221, 202);
  border-radius: 10%;
  z-index: 2;  
  position: relative;
  left: 70px;
  border: 0.1rem solid #ffffff;
}
.img-holder img{
  width: 100%;
  height: 100%;
  border-radius: 10%;
  transition: box-shadow 0.3s;
}
.img-holder:hover
{
  box-shadow: inset 0 0 30px rgba(238, 236, 236, 0.781);
}
.back-card {
  width: 20rem;
  height: 20rem;
  background-color:#e0bcff;
  border-radius: 10%;
  position: relative;
  top: 20px;
  left: 350px;
  z-index: 1;
  border: 0.1rem solid #ffffff;
}
.profile-card-content {
  position: absolute;
  top: 50%; 
  left: 16%; 
  transform: translateY(-50%);
  color: rgb(255, 254, 254);
  opacity: 1;
  font-size: 16px;
  width: 600px;
  padding-bottom: 1%;
}

@keyframes slideInRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.profile-card-content h2 {
  color: #e0bcff;
  opacity: 0; 
  transform: translateX(-100%); 
  transition: transform var(--transition-duration) var(--transition-easing), opacity var(--transition-duration) var(--transition-easing);
  font-weight: 600;
}

.profile-card.show-content .profile-card-content h2 {
  animation: slideInRight var(--transition-duration) var(--transition-easing) forwards; 
}
.profile-card-content h2 {
  position: relative;
  top: -30px;
}
.primay-color{
  color: #ffbf00;
}
.promo p .startup{
  color: #ff5900;
  font-size: 30px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .profile-card {
    width: 90%;
    height: 107vh;
    display: block;
    border: none;
    top: 15%;
  }
.profile-card-content
{
  left: 15%;
  top: 55%;
  width: 90%;
}
  .profile-card-background {
    left: 0px; 
  }

 
  .profile-card-content p{
  font-size: 14px;
  }
  .profile-card-content h2 {
    position: relative;
    left: 0px;
    padding-top: 10px;
    margin: 0%;
  }

  .profile-card.show-content .profile-card-content {
    left: 5%; /* Adjust position once visible */
    padding-top: 50px;
    overflow: hidden;
    
  }

  .img-holder,
  .back-card {
    width: 5rem;
    height: 5rem;
  }

  .back-card {
    position: absolute;
    top: 40px;
    left: 110px;
    width: 10rem;
    height: 10rem;
    z-index: 1;
  }

  .img-holder {
    position: absolute;
    top: 30px;
    left: 100px;
    width: 10rem;
    height: 10rem;
  }
}
