.evangelist-container
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2%;
    padding-bottom: 5%;
    color: #5a2a83;

}
.line
{
  width: 89%;
  border-bottom:0.1rem solid #7474747c ;
  margin-bottom: 2%;
}
.h3
{
  font-size: 35px;
font-weight: 700;
margin-bottom: 2%;
color:#5a2a83;
}
.click-me-btn {
    background-color: #5a2a83; /* Yellow background */
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 10px;
    margin-top: 0%;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    position: absolute;
    bottom: 0px; /* Position the button right under the card */
    left: 0;
  }
  
  .click-me-btn:hover {
    background-color: #5a2a83;
  }

.inside-container
{
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 5%;
    font-family: Arial, Helvetica, sans-serif;
    
  
}

  
  .flip-card {
    background-color: transparent;
    width: 350px;
    height: 500px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border: 0.1rem solid #5a2a83;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
  
    background-size: cover; 
    color: black;
  }
  .flip-card-front img
  {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .flip-card-back {
    background-color: #070707;
    color: white;
    transform: rotateY(180deg);
    text-align: left;
    
  }

  .flip-card-back .align
  {
    width: 100%;
    height: 100% !important; 
    position: absolute;
    padding: 15px;
    padding-top: 5%;
  }

  .align h3{
    color: #ffffff;
    font-weight: 600;
    font-size: 25px;
  }

  .align p{
    text-align: justify;
    font-size: 14px;
  }
  .line-e{
    width: 20%;
    height: 2px;
    background-color: #b26cee;
    margin-bottom: 10px;
  }

  @media (max-width:768px)
  {
    .evangelist-container
{
  position: relative;
  top: 322px;
}
    .h3
    {
      font-size: 27px;
    }
    .inside-container
    {
        flex-direction: column;
      gap: 50px;
    }
    .flip-card
    {
        margin-bottom: 5%;
    }
    .flip-card-front
    {
      width: 90%;
      margin-left: 20px;
    }
    .flip-card-back{
      width: 90%;
      height: 100%;
      margin-left: 20px;
      font-size: 14px;
      padding-top: 25px;
    }
    
    .flip-card-inner 
    {
      border:none;
    }
    .line-e
    {
     width: 20%;
    }
  }