.landing_page * {
    font-family: Nunito, sans-serif;
  }
  
  .landing_page .responsive-container-block {
    min-height: 75px;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    justify-content: flex-start;
  }
  
  .landing_page .text-blk {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    line-height: 25px;
  }
  
  .landing_page .responsive-cell-block {
    min-height: 75px;
  }
  
  .landing_page .responsive-container-block.container {
    max-width: 1320px;
    margin-top: 60px;
    margin-right: auto;
    margin-bottom: 60px;
    margin-left: auto;
    position: relative;
  }
  
  .landing_page .form-box {
    background-color: #151617;
    color: white;
    padding-top: 35px;
    padding-right: 33px;
    padding-bottom: 35px;
    padding-left: 33px;
    max-width: 506px;
  }
  
  .landing_page .text-blk.contactus-head {
    font-size: 20px;
    line-height: 40px;
  }
  
  .landing_page .text-blk.contactus-subhead {
    color: #d4d4d4;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 18px;
    margin-left: 0px;
  }
  

  .landing_page .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-6.wk-ipadp-6.emial {
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  .landing_page .responsive-cell-block.wk-ipadp-6.wk-tab-12.wk-mobile-12.wk-desk-6.right-one {
    display: flex;
    justify-content: center;
    position: relative;
    left: 200px;
  }
  @media(max-width:768px)
  {
    .landing_page .responsive-cell-block.wk-ipadp-6.wk-tab-12.wk-mobile-12.wk-desk-6.right-one {
      position: relative;
      left: -2%;
    }
    .landing_page
    {
      position: relative;
      top: 330px;
    }

  }
  .landing_page .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12.left-one {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
  }
  
  .landing_page .text-blk.section-subhead {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
    max-width: 420px;
    font-size: 18px;
    color: #b6b6b6;
  }
  
  .landing_page .text-blk.section-head {
    font-size: 40px;
    line-height: 55px;
    font-weight: 800;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    max-width: 450px;
  }
  
  .landing_page .icons-container {
    max-width: 450px;
    display: flex;
    justify-content: space-evenly;
  }
  
  .landing_page .img {
    width: 31px;
    height: 31px;
  }
  
  .landing_page .responsive-container-block.big-container {
    background-color: black;
    padding-top: 0px;
    padding-right: 50px;
    padding-bottom: 0px;
    padding-left: 50px;
    position: relative;
  }
  
  .landing_page .bg-img {
    width: 100%;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    height: 100%;
    opacity: 0.5;
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    .landing_page .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12.left-one {
      justify-content: center;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 30px;
      margin-left: 0px;
    }
  
    .landing_page .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-6.wk-ipadp-6.emial {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .landing_page .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12.left-one {
      margin: 0 0 40px 0;
    }
  }
  
  @media (max-width: 500px) {
    .landing_page .text-blk.section-head {
      font-size: 26px;
      text-transform: uppercase;
      line-height: 40px;
    }
  
    .landing_page .responsive-container-block.big-container {
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
  
    .landing_page .text-blk.section-subhead {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 30px;
      margin-left: 0px;
      font-size: 12px;
    }
    .landing_page .text-blk.section-subhead h4
    {
      font-size: 16px;
    }
    .landing_page .form-box {
      padding-top: 30px;
      padding-right: 0px;
      padding-bottom: 30px;
      padding-left: 0px;
    }
  
    .landing_page .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12.left-one {
      margin: 0 0 30px 0;
    }
  
    .landing_page .input {
      height: 45px;
    }
  
    .landing_page .text-blk.contactus-head {
      font-size: 24px;
      line-height: 34px;
    }
  
  }






  /**/

  .social-media-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .social-media-icons .icon {
    color: #333;
    transition: transform 0.3s, color 0.3s;
  }
  
  .social-media-icons .icon:hover {
    color: #0073e6; /* Hover color */
    transform: scale(1.1); /* Slight zoom effect */
  }
  
  .social-media-icons .icon:active {
    transform: scale(0.9); /* Slight shrink effect on click */
  }
  /**/