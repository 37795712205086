$total: 100;
$time: 10s;
$radius: 150px; // Radius of the curve
$start-angle: -45deg; // Start angle of the text
$end-angle: 45deg; // End angle of the text

@font-face {
  font-family: 'Varsity Team';
  src: url('./fonts/VarsityTeam-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'myriad pro';
  src: url('./fonts/MYRIADPRO-SEMIBOLD.OTF') format('opentype');
  font-weight: bold;
  font-style: normal;
}

html, body, .wrap {
  height: 100%;
}


.main-container {
  background: #5a2a83;
  background-image: radial-gradient(circle at center, rgba(255, 255, 255, 0.897) 0%, #d3b1ffcb 10%, #5a2a83bd 40%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  font-size: 35px;
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height: 100vh; 

@media (max-width:768px)
{
  height: 40vh;
}

}
path {
    fill: transparent;
  }

  text {
    position: relative;
    fill: #fff;
  }
.wrap {
    position: relative; 
    transform-style: preserve-3d;
    perspective: 800px;
}
.centered-text {
    position: absolute;
    width: 1400px;
    height: auto;
    transform: translate(-50%, -50%);
    text-align: center; /* Center align the text */
    z-index: 10; /* Ensure text appears above triangles */
  }

  .head-text{
    font-family: 'Varsity Team', serif;
    color: #fff; /* Text color */
    font-size: 10rem; 
    font-weight: 700;
    position: relative;
    top:330px;
    

    @media (max-width: 1200px) {
      font-size: 8rem;
    }
  
    @media (max-width: 992px) {
      font-size: 6rem;
    }
  
    @media (max-width: 768px) {
      font-size: 4rem;
      top:180px;
    }
  
    @media (max-width: 576px) {
      font-size: 3rem;
    }
  }
  .head-small{
    font-family: "myriad pro", serif;
    font-weight: 50;
    font-size: 3rem;
    font-style: normal;
    color: #ffffff;
    position: relative;
    top:300px;
    letter-spacing: 3.5px;

  @media (max-width: 1200px) {
    font-size: 2.5rem;
    top:310px;
  }

  @media (max-width: 992px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    top:160px;
    letter-spacing: 2.5px;
  }

  @media (max-width: 576px) {
    font-size: 1rem;
  
  }
  }

.tri {
  height: 0;
  width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

@for $i from 1 through $total {
  $size: random(50) * 1px;
  $rotate: random(360) * 1deg;
  $animationDuration: random(10) + 5 + s;

  .tri:nth-child(#{$i}) {
    border-top: $size solid hsla(random(360), 100%, 50%, 1);
    border-right: $size solid transparent;
    border-left: $size solid transparent;
    margin-left: -$size / 2;
    margin-top: -$size / 2;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    transform: rotate($rotate) translate3d(0, 0, -1500px) scale(0);
    animation: anim#{$i} $time infinite linear;
    animation-delay: $i * -($time / $total);
    opacity: 0;
  }

  @keyframes anim#{$i} {
    0% {
      opacity: 1;
      transform: rotate($rotate * 1.5) translate3d(random(1000) * 1px, random(1000) * 1px, 1000px) scale(1);
    }
    50% {
        transform: rotate($rotate * 1.25) translate3d(random(500) * 1px, random(500) * 1px, 500px) scale(0.5);
        opacity: 0.7;
      }
      100% {
        opacity: 0;
        transform: rotate($rotate) translate3d(random(1000) * 1px, random(1000) * 1px, -1500px) scale(0);
      }
  }
}
.curved-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px; /* Adjust based on your needs */
    height: 150px; /* Adjust based on your needs */
    position: relative;
    transform: rotate(-80deg); /* Optional: To start from the bottom */
  
    span {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: 0 100%; /* Transform around the bottom of each letter */
      font-size: 24px;
  
      @for $i from 1 through 11 { // Adjust based on the number of characters
        &:nth-child(#{$i}) {
          $angle: $start-angle + (($end-angle - $start-angle) / 10) * ($i - 1);
          transform: rotate($angle) translate($radius);
        }
      }
    }
  }