
.servicepage
{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10%;
  padding-top: 3%;
  color: #ffff;
  position: relative;
}
.yellow-back
{
  width: 85%;
  height: auto;
  background-color: #5a2a83;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
 gap: 10px;
 padding-left: 35px;
 padding-right: 35px;
 padding-top: 5%;
  padding-bottom: 5%;
 flex-wrap: wrap;
}
  
.services
{
  width: 300px;
  height: 230px;
  background:#5a2a83;
  background-size: cover;
  color: #ffffff;
  position: relative;
  box-sizing: border-box;
  border-radius:5%;
  font-weight: 600;
  border: 0.1rem solid #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}

.services:hover
{
  box-shadow: inset 0 0 30px rgba(238, 236, 236, 0.781);
}

.servicepage h3{
font-size: 35px;
font-weight: 600;
margin-bottom: 2%;
color:#5a2a83;
}
.services p {
  font-size: 18px;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  padding-top:5px ;
}
@media (max-width:768px)
{
.yellow-back
{

  background-color: transparent;
  flex-direction: column;
  align-items: center; /* Center items */
  padding: 0px; 
  gap: 40px;
}
.services
{
  height: 200px; /* Adjust height for mobile */
  padding-left: 0%; /* Remove left padding */
  text-align: center;
}
.services p {
  font-size: 14px;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  padding-top:5px ;
}
.servicepage
{
  margin-top: 350px;
}
.servicepage h1
{
  font-size: 35px;
  margin-bottom: 30px;
}
  
  
}