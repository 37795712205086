/********** Template CSS **********/
/*** Spinner ***/
body{
 
}

#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}

img.animated.pulse {
    animation-duration: 5s;
}

/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;
}

.btn.btn-primary {
    color: var(--bs-white);
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}
.promo img
{
width: 750px;
}
.promo{
    width: 100%;
    
    align-items: center;
    text-align: center;
}

.promo p span{
    color: blueviolet;
}
.home
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
/*** Navbar ***/
.sticky-top {
    top: -150px;
    transition: .5s;
}
.navbar {
    padding: 11px 0 !important;
    height: 85px;
}
.navbar .navbar-nav .nav-link {
    margin-right: 45px;
    padding: 0;
    color: var(--bs-dark);
    font-weight: 500;
    transition: .5s;
    outline: none;
}
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: var(--bs-white);
}
.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}
@media (max-width: 991.98px) {
    .navbar .navbar-nav {
        padding: 0 15px;
        background: var(--bs-primary);
    }
    .navbar .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }
}
@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }
    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}

/*** Footer ***/
.footer .btn.btn-link {
    display: block;
    margin-bottom: 10px;
    padding: 0;
    text-align: left;
    color: var(--bs-body);
    font-weight: normal;
    transition: .3s;
}

.footer .btn.btn-link:hover {
    color: var(--bs-primary);
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    border-top: 3px solid var(--bs-light);
}

.footer .copyright a {
    color: var(--bs-body);
}

.footer .copyright a:hover {
    color: var(--bs-primary);
}

.footer .footer-menu a {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 3px solid var(--bs-light);
}

.footer .footer-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}