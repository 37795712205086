/* About Section */

.about {
    
    background: #ffbf00;
    padding: 100px 0 20px 0;
    text-align: center;
    color: #fffafa;
}

.text-content {
    overflow: hidden;
    text-overflow: ellipsis;
    transition: max-height 0.5s ease;
    max-height: 15em; /* Initially show only 5 lines */
    line-height: 1.5em;
    width: 700px;
    margin-top: 10%;
  }
  
  .text-content.expanded {
    max-height: 100%; /* Expand to show all content */
  }
  

.about h1 {
    font-size: 3.5rem;
  
}

.about p {
    font-size: 1rem;
    color: #ffffff;
    max-width: 800px;
    margin: 0 auto;
    font-weight: 700;
}

.about-info {
  
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.about-img {
    width: 20rem;
    height: 20rem;

}

.about-img img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: contain;
}

.about-info p {
    font-size: 1.3rem;
    margin: 0 2rem;
    text-align: justify;
}

.about-btn {
    border: none;
    outline: 0;
    padding: 10px;
    margin: 2rem;
    font-size: 1rem;
    color: white;
    background-color: #000000;
    text-align: center;
    cursor: pointer;
    width: 15rem;
    border-radius: 4px;
}

.about-btn:hover {
    background-color: #f0d90a;
}



/* Footer */
footer {
    background-color: #222;
    color: white;
    text-align: center;
    padding: 20px 0;
}

@media (max-width: 768px) {
    nav {
        display: block;
    }

    .logo {
        text-align: center;
    }

    .nav-links {
        margin-top: 1rem;
        justify-content: space-between;
    }
    

    .nav-links li {
        margin-right: 0;
    }

    .about h1 {
        font-size: 2rem;
    }

    .about p {
        font-size: 1rem;
    }

    .about-info {
        flex-direction: column;
        text-align: center;
        margin: 0%;
    } 

    .about-img {
        width: 70%;
        height: 70%;
        margin-bottom: 1rem;
    }

    .about-info p {
        margin: 1rem 2rem;
    }

    .about-info button {
        margin: 1rem 2rem;
        width: 10rem;
    }

    .team {
        margin: 0 1rem;
    }

    .text-content
    {
        width: 100%;
    }
}