.pdfcontainer
{
  display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 40px;
width: 100%;
height: 100%;
background: url('../imgs/11906057_4853351.jpg');
background-attachment: fixed;
background-size: cover; 
background-position: center center; 
background-repeat: no-repeat;
padding-top: 10%;
padding-bottom: 10%;

}
.pdfcontainer .container1 .container2 .container3{
  
  cursor: pointer;
}
.pdfcontainer img
{
  width: 500px;
 
  overflow: hidden;
  cursor: pointer;
  
}
.img1, .img2
{
  object-fit: cover;
  height: 300px;
}



  .container2 ,.container3
{
  background-color: #ffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 5%;
}
.container1
{
  background-color: #ffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 5%;
  width: 75%;
}
.btn
{
  background-color: transparent;
  border: 0.2rem solid #5a2a83;
  padding: 2%;
  font-weight: 600;
}
.para
{
  font-size: 20px;
  font-weight: 600;
  color: #5a2a83;
}
.btn:hover{
  background-color: #5a2a83;
  color: #ffff;
}
@media(max-width:768px)
{
.pdfcontainer img
{
    width: 250px;
  }
  .para{
    font-size: 17px;
  }
  .img3
  {
    height: 150px;
  }
}